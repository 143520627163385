<template>
  <div class="home" ref="home">
    <div
      class="top-back"
      style="justify-content: space-between; align-items: center"
    >
      <!-- <svg-icon
        style="width: 70px; height: 24px; cursor: pointer;"
        iconClass="back"
        className="icons/svg/back.svg"
        @click="back"
      ></svg-icon> -->
      <div style="flex: 1; text-align: left" @click="reset">
        <svg-icon
          class="label-setting"
          iconClass="home"
          style="width: 22px; height: 22px"
          className="icons/svg/home.svg"
          :style="{ visibility: commitType != 'RRCM' ? '' : 'hidden' }"
        ></svg-icon>
      </div>
      <div style="flex: 1; text-align: right" @click="clickOpen(2)">
        <svg-icon
          class="label-setting"
          style="width: 22px; height: 22px"
          iconClass="setting"
          className="icons/svg/setting.svg"
        ></svg-icon>
      </div>
    </div>
    <div class="title" v-if="false">
      <!-- <div class="label" v-html="stringList.room"></div> -->
      <svg-icon
        class="label-setting"
        iconClass="home"
        className="icons/svg/home.svg"
        :style="{ visibility: commitType != 'RRCM' ? '' : 'hidden' }"
        @click="reset"
      ></svg-icon>
      <a-select
        v-if="commitType != 'RS'"
        show-search
        size="large"
        style="flex: 1; margin: 0 20px"
        class="room-num"
        :placeholder="stringList.room"
        :getPopupContainer="() => $refs.home"
        :value="roomNumber"
        @dropdownVisibleChange="clickOpen(1)"
        :open="false"
      >
        <a-select-option
          v-for="item in roomArray"
          :key="item.id"
          :value="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <span v-else style="flex: 1; margin: 0 20px" class="username room-num">
        <svg-icon
          class="label-setting"
          iconClass="username"
          style="width: 20px; height: 20px; margin-right: 10px"
          className="icons/svg/username.svg"
        ></svg-icon>
        {{ userName }}
      </span>
      <div @click="clickOpen(2)">
        <svg-icon
          class="label-setting"
          iconClass="setting"
          className="icons/svg/setting.svg"
        ></svg-icon>
      </div>
    </div>
    <div
      class="content"
      :class="this.label2.length > 0 ? 'small' : 'small less'"
    >
      <div
        class="content-title"
        v-if="commitType == 'RRC' || commitType == 'RRCM'"
      >
        {{ stringList.ReportRoomCharge }}
      </div>
      <a-form id="components-form-check" :form="form" @submit="handleSubmit">
        <!-- :label="stringList.bottle_water" -->
        <template v-if="commitType == 'RRC' || commitType == 'RRCM'">
          <div class="num-item">
            <div class="label">{{ stringList.room }}</div>
            <a-form-item
              style="flex: 1; margin-bottom: 0"
              :validate-status="validateStatus || 'success'"
              :help="errorMsg || ''"
            >
              <a-select
                show-search
                size="large"
                style="flex: 1; margin: 0"
                class="room-num"
                dropdownClassName="num-drop"
                :placeholder="stringList.room"
                v-model="roomNumber"
              >
                <a-select-option
                  v-for="item in roomArray"
                  :key="item.id"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>

          <div class="room-type">
            <a-radio-group v-model="roomType">
              <a-radio style="display: block" value="1">
                {{ stringList.vacant_checked_out }}
              </a-radio>
              <a-radio style="display: block" value="2">
                {{ stringList.occupied_stay_over }}
              </a-radio>
            </a-radio-group>
          </div>
          <a-form-item
            class="item"
            :label="
              label1.find((item) => item.keyword == 'Bottled Water')
                ? label1.find((item) => item.keyword == 'Bottled Water')[
                    language
                  ] ||
                  label1.find((item) => item.keyword == 'Bottled Water').keyword
                : stringList.bottle_water
            "
          >
            <a-select
              v-model="formData.water.num"
              :class="formData.water.selectErrorType == 5 ? 'select-error' : ''"
              @select="submitV()"
            >
              <a-select-option :value="item - 1" v-for="item in 3" :key="item">
                {{ item - 1 }}
              </a-select-option>
            </a-select>
            <div class="icon-button" @click="clickOpen(4, ['water', 'photo'])">
              <svg-icon
                class="photograph"
                iconClass="photograph_normal"
                className="icons/svg/photograph_normal.svg"
              ></svg-icon>

              <span
                class="little-icon"
                :style="
                  formData.water.photo.length == 1
                    ? 'width:14px;height:14px'
                    : ''
                "
                v-if="formData.water.photo.length >= 1"
                >{{
                  formData.water.photo.length > 1
                    ? formData.water.photo.length
                    : ""
                }}</span
              >
            </div>
            <div
              class="icon-button"
              @click="clickOpen(3, ['water', 'message'])"
            >
              <svg-icon
                class="edit"
                iconClass="edit_normal"
                className="icons/svg/edit_normal.svg"
              ></svg-icon>
              <span
                class="little-icon"
                style="width: 14px; height: 14px"
                v-if="formData.water.message"
              ></span>
            </div>
          </a-form-item>
          <!-- :label="stringList.pet_in_room" -->
          <a-form-item
            class="item"
            :class="formData.pet.errorType == 2 ? 'photo-error' : ''"
            :label="
              label1.find((item) => item.keyword == 'Pet in Room')
                ? label1.find((item) => item.keyword == 'Pet in Room')[
                    language
                  ] ||
                  label1.find((item) => item.keyword == 'Pet in Room').keyword
                : stringList.two_pet_in_room
            "
            :help="formData.pet.errorMessage"
            :validate-status="formData.pet.errorType != 0 ? 'error' : ''"
          >
            <a-select
              v-model="formData.pet.type"
              :class="formData.pet.selectErrorType == 5 ? 'select-error' : ''"
              @select="submitV()"
            >
              <a-select-option :value="0">
                {{ stringList.No }}
              </a-select-option>
              <a-select-option :value="1">
                {{ stringList.Yes }}
              </a-select-option>
            </a-select>
            <div
              class="icon-button required"
              :class="formData.pet.errorType == 2 ? 'has-error-item' : ''"
              @click="clickOpen(4, ['pet', 'photo'])"
            >
              <svg-icon
                v-if="!(formData.pet.errorType == 2)"
                class="photograph"
                iconClass="photograph_normal"
                className="icons/svg/photograph_normal.svg"
              ></svg-icon>
              <svg-icon
                v-else
                class="photograph"
                iconClass="photograph_error"
                className="icons/svg/photograph_error.svg"
              ></svg-icon>
              <span
                class="little-icon"
                :style="
                  formData.pet.photo.length == 1 ? 'width:14px;height:14px' : ''
                "
                v-if="formData.pet.photo.length >= 1"
                >{{
                  formData.pet.photo.length > 1 ? formData.pet.photo.length : ""
                }}</span
              >
            </div>
            <div class="icon-button" @click="clickOpen(3, ['pet', 'message'])">
              <svg-icon
                class="edit"
                iconClass="edit_normal"
                className="icons/svg/edit_normal.svg"
              ></svg-icon>
              <span
                class="little-icon"
                style="width: 14px; height: 14px"
                v-if="formData.pet.message"
              ></span>
            </div>
          </a-form-item>
          <!-- :label="label1.find(item=>item.keyword=='Smoking/Vaping')?label1.find(item=>item.keyword=='Smoking/Vaping')[language]||label1.find(item=>item.keyword=='Smoking/Vaping').keyword:stringList.smoking" -->
          <a-form-item
            class="item"
            :label="stringList.smoking"
            :class="formData.smoking.errorType == 2 ? 'photo-error' : ''"
            :help="formData.smoking.errorMessage"
            :validate-status="formData.smoking.errorType != 0 ? 'error' : ''"
          >
            <a-select
              v-model="formData.smoking.type"
              :class="
                formData.smoking.selectErrorType == 5 ? 'select-error' : ''
              "
              @select="submitV()"
            >
              <a-select-option :value="0">
                {{ stringList.No }}
              </a-select-option>
              <a-select-option :value="1">
                {{ stringList.Yes }}
              </a-select-option>
            </a-select>
            <div
              class="icon-button required"
              :class="
                !!(
                  formData.smoking.errorType == 2 ||
                  formData.smoking.errorType == 4
                )
                  ? 'has-error-item'
                  : ''
              "
              @click="clickOpen(4, ['smoking', 'photo'])"
            >
              <svg-icon
                v-if="
                  !(
                    formData.smoking.errorType == 2 ||
                    formData.smoking.errorType == 4
                  )
                "
                class="photograph"
                iconClass="photograph_normal"
                className="icons/svg/photograph_normal.svg"
              ></svg-icon>
              <svg-icon
                v-else
                class="photograph"
                iconClass="photograph_error"
                className="icons/svg/photograph_error.svg"
              ></svg-icon>
              <span
                class="little-icon"
                :style="
                  formData.smoking.photo.length == 1
                    ? 'width:14px;height:14px'
                    : ''
                "
                v-if="formData.smoking.photo.length >= 1"
                >{{
                  formData.smoking.photo.length > 1
                    ? formData.smoking.photo.length
                    : ""
                }}</span
              >
            </div>
            <div
              class="icon-button required"
              :class="
                !!(
                  formData.smoking.errorType == 3 ||
                  formData.smoking.errorType == 4
                )
                  ? 'has-error-item'
                  : ''
              "
              @click="clickOpen(3, ['smoking', 'message'])"
            >
              <svg-icon
                v-if="
                  !(
                    formData.smoking.errorType == 3 ||
                    formData.smoking.errorType == 4
                  )
                "
                class="edit"
                iconClass="edit_normal"
                className="icons/svg/edit_normal.svg"
              ></svg-icon>
              <svg-icon
                v-else
                class="edit"
                iconClass="edit_error"
                className="icons/svg/edit_error.svg"
              ></svg-icon>
              <span
                class="little-icon"
                style="width: 14px; height: 14px"
                v-if="formData.smoking.message"
              ></span>
            </div>
          </a-form-item>
          <a-form-item
            class="item"
            v-for="item of label11"
            :key="item.id"
            :class="formData[item.id].errorType == 2 ? 'photo-error' : ''"
            :label="
              language=='en'?item.keyword:item[language]
            "
            :help="formData[item.id].errorMessage"
            :validate-status="formData[item.id].errorType != 0 ? 'error' : ''"
          >
            <a-select
              v-model="formData[item.id].type"
              :class="formData[item.id].selectErrorType == 5 ? 'select-error' : ''"
              @select="submitV()"
            >
              <a-select-option :value="0">
                {{ stringList.No }}
              </a-select-option>
              <a-select-option :value="1">
                {{ stringList.Yes }}
              </a-select-option>
            </a-select>
            <div
              class="icon-button required"
              :class="formData[item.id].errorType == 2 ? 'has-error-item' : ''"
              @click="clickOpen(4, [item.id, 'photo'])"
            >
              <svg-icon
                v-if="!(formData[item.id].errorType == 2)"
                class="photograph"
                iconClass="photograph_normal"
                className="icons/svg/photograph_normal.svg"
              ></svg-icon>
              <svg-icon
                v-else
                class="photograph"
                iconClass="photograph_error"
                className="icons/svg/photograph_error.svg"
              ></svg-icon>
              <span
                class="little-icon"
                :style="
                  formData[item.id].photo.length == 1 ? 'width:14px;height:14px' : ''
                "
                v-if="formData[item.id].photo.length >= 1"
                >{{
                  formData[item.id].photo.length > 1 ? formData[item.id].photo.length : ""
                }}</span
              >
            </div>
            <div class="icon-button" @click="clickOpen(3, [item.id, 'message'])">
              <svg-icon
                class="edit"
                iconClass="edit_normal"
                className="icons/svg/edit_normal.svg"
              ></svg-icon>
              <span
                class="little-icon"
                style="width: 14px; height: 14px"
                v-if="formData[item.id].message"
              ></span>
            </div>
          </a-form-item>
          <div
            class="damages-list"
            v-for="(item, index) of formData.damages"
            :key="index"
          >
            <a-form-item
              class="item list-item"
              :class="item.errorType == 2 ? 'photo-error' : ''"
              :label="getLabel('guestroom_damage', index)"
              :help="item.errorMessage"
              :validate-status="item.errorType != 0 ? 'error' : ''"
            >
              <div
                class="icon-button required"
                :class="
                  !!(item.errorType == 2 || item.errorType == 4)
                    ? 'has-error-item'
                    : ''
                "
                @click="clickOpen(4, ['damages', index, 'photo'])"
              >
                <svg-icon
                  v-if="!(item.errorType == 2 || item.errorType == 4)"
                  class="photograph"
                  iconClass="photograph_normal"
                  className="icons/svg/photograph_normal.svg"
                ></svg-icon>
                <svg-icon
                  v-else
                  class="photograph"
                  iconClass="photograph_error"
                  className="icons/svg/photograph_error.svg"
                ></svg-icon>
                <span
                  class="little-icon"
                  :style="
                    item.photo?.length == 1 ? 'width:14px;height:14px' : ''
                  "
                  v-if="item.photo?.length >= 1"
                  >{{ item.photo?.length > 1 ? item.photo?.length : "" }}</span
                >
              </div>
              <div
                class="icon-button"
                :class="
                  !!(item.errorType == 3 || item.errorType == 4)
                    ? 'has-error-item'
                    : ''
                "
                @click="clickOpen(3, ['damages', index, 'message'])"
              >
                <svg-icon
                  v-if="!(item.errorType == 3 || item.errorType == 4)"
                  class="edit"
                  iconClass="edit_normal"
                  className="icons/svg/edit_normal.svg"
                ></svg-icon>
                <svg-icon
                  v-else
                  class="edit"
                  iconClass="edit_error"
                  className="icons/svg/edit_error.svg"
                ></svg-icon>
                <span
                  class="little-icon"
                  style="width: 14px; height: 14px"
                  v-if="item.message"
                ></span>
              </div>
            </a-form-item>
            <div style="width: 100%; margin-bottom: 11px">
              <div
                style="
                  position: relative;
                  width: calc(100% - 16px);
                  display: flex;
                  align-items: center;
                "
              >
                <a-textarea
                  class="text-area"
                  :class="
                    item.errorType != 0 && !item.description
                      ? 'damages-item-error'
                      : ''
                  "
                  :placeholder="stringList.enter_guestroom_damage"
                  :rows="4"
                  v-model="item.description"
                  @input="
                    checkInput(item.description, index);
                    submitV();
                  "
                />
                <div
                  @click="reduceDamages(index)"
                  style="
                    position: absolute;
                    right: -18px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                  "
                >
                  <svg-icon
                    v-if="formData.damages.length > 1"
                    style="width: 14px; height: 1.01px"
                    class="photograph"
                    iconClass="reduce"
                    className="icons/svg/reduce.svg"
                  ></svg-icon>
                </div>
              </div>
              <div class="error-message">{{ item.errorMoreMessage }}</div>
            </div>
          </div>
          <div class="add-damages" style="margin-bottom: 0px !important">
            <svg-icon
              @click="addDamages()"
              style="width: 14px; height: 14px"
              iconClass="add"
              className="icons/svg/add.svg"
            ></svg-icon>
            <div class="text" @click="addDamages()">
              {{ stringList.guestroom_damage }}
            </div>
          </div>
        </template>
        <template v-if="commitType == 'RM'">
          <div
            class="maintenance"
            :class="formData.items.length > 1 ? 'items-num' : ''"
            style="
              padding-top: 0 !important;
              padding-bottom: 0 !important;
              background: #fff0;
            "
            v-if="this.label2.length > 0"
          >
            <div class="content-title">
              {{ stringList.RequestMaintenance }}
            </div>
            <div class="num-item">
              <div class="label">{{ stringList.room }}</div>
              <a-form-item
                style="flex: 1; margin-bottom: 0"
                :validate-status="validateStatus || 'success'"
                :help="errorMsg || ''"
              >
                <a-select
                  show-search
                  size="large"
                  style="flex: 1; margin: 0"
                  class="room-num"
                  dropdownClassName="num-drop"
                  :placeholder="stringList.room"
                  v-model="roomNumber"
                >
                  <a-select-option
                    v-for="item in roomArray"
                    :key="item.id"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>

            <div class="room-type">
              <a-radio-group v-model="roomType">
                <a-radio style="display: block" value="1">
                  {{ stringList.vacant_checked_out }}
                </a-radio>
                <a-radio style="display: block" value="2">
                  {{ stringList.occupied_stay_over }}
                </a-radio>
              </a-radio-group>
            </div>
            <a-form-item
              class="item items2"
              :label="item.label ? item.label[language] : ''"
              :help="item.errorMessage"
              v-for="(item, index) in formData.items2"
              :key="index + '333'"
            >
              <a-select
                v-if="item.select[0].action"
                v-model="item.type"
                :class="item.selectErrorType == 5 ? 'select-error' : ''"
                :placeholder="stringList.select"
                dropdownClassName="items2-select"
                @select="
                  (value) => {
                    if (value === 'select') item.type = undefined;
                    else if (item.selectErrorType == 5) submitV();
                  }
                "
              >
                <a-select-option value="select" key="select">
                  {{ stringList.select }}
                </a-select-option>
                <a-select-option
                  :value="it.id"
                  v-for="it in item.select"
                  :key="it.id"
                >
                  {{ it.action[language] }}
                </a-select-option>
              </a-select>
              <a-select
                v-else
                v-model="item.type"
                :placeholder="stringList.select"
                :class="item.selectErrorType == 5 ? 'select-error' : ''"
                @select="submitV()"
              >
                <a-select-option :value="0">
                  {{ stringList.No }}
                </a-select-option>
                <a-select-option :value="item.noSelectType">
                  {{ stringList.Yes }}
                </a-select-option>
              </a-select>
              <div
                class="icon-button"
                @click="clickOpen(4, ['items2', index, 'photo'])"
              >
                <svg-icon
                  class="photograph"
                  iconClass="photograph_normal"
                  className="icons/svg/photograph_normal.svg"
                ></svg-icon>

                <span
                  class="little-icon"
                  :style="
                    item.photo.length == 1 ? 'width:14px;height:14px' : ''
                  "
                  v-if="item.photo.length >= 1"
                  >{{ item.photo.length > 1 ? item.photo.length : "" }}</span
                >
              </div>
              <div
                class="icon-button"
                @click="clickOpen(3, ['items2', index, 'message'])"
              >
                <svg-icon
                  class="edit"
                  iconClass="edit_normal"
                  className="icons/svg/edit_normal.svg"
                ></svg-icon>
                <span
                  class="little-icon"
                  style="width: 14px; height: 14px"
                  v-if="item.message"
                ></span>
              </div>
            </a-form-item>
            <!-- <a-form-item
            class="item"
            :label="stringList.sink"
            :help="formData.sink.errorMessage"
          >
            <a-select
              v-model="formData.sink.type"
              :class="formData.sink.selectErrorType == 5 ? 'select-error' : ''"
              :placeholder="stringList.select"
              @select="formData.sink.selectErrorType == 5 ? submitV() : ''"
            >
              <a-select-option
                :value="item.id"
                v-for="item of sinkList"
                :key="item.id"
              >
                {{ item.action[language] }}
              </a-select-option>
            </a-select>
            <div class="icon-button" @click="clickOpen(4, ['sink', 'photo'])">
              <svg-icon
                class="photograph"
                iconClass="photograph_normal"
                className="icons/svg/photograph_normal.svg"
              ></svg-icon>

              <span
                class="little-icon"
                :style="
                  formData.sink.photo.length == 1
                    ? 'width:14px;height:14px'
                    : ''
                "
                v-if="formData.sink.photo.length >= 1"
                >{{
                  formData.sink.photo.length > 1
                    ? formData.sink.photo.length
                    : ""
                }}</span
              >
            </div>
            <div class="icon-button" @click="clickOpen(3, ['sink', 'message'])">
              <svg-icon
                class="edit"
                iconClass="edit_normal"
                className="icons/svg/edit_normal.svg"
              ></svg-icon>
              <span
                class="little-icon"
                style="width: 14px; height: 14px"
                v-if="formData.sink.message"
              ></span>
            </div>
          </a-form-item> -->
            <div v-for="(item, index) of formData.items" :key="index + '22'">
              <a-form-item
                class="item items list-item"
                :class="item.errorType == 2 ? 'photo-error' : ''"
                :help="item.errorMessage"
                :validate-status="item.errorType != 0 ? 'error' : ''"
              >
                <div
                  style="flex: 1; display: flex; align-items: center"
                  :style="formData.items.length > 1 ? 'margin-right: 10px' : ''"
                >
                  <a-select
                    v-model="item.type"
                    class="select-item"
                    :class="
                      item.selectErrorType == 5 && item.type == undefined
                        ? 'select-error'
                        : ''
                    "
                    style="flex: 1; margin-right: 8px"
                    :style="
                      formData.items.length > 1 ? 'margin-right: 0px' : ''
                    "
                    @dropdownVisibleChange="item.type = undefined"
                    show-search
                    :filter-option="false"
                    option-filter-prop="children"
                    @search="itemsSearch($event, index)"
                    :ref="'selectItem' + index"
                    dropdownClassName="other-select"
                    :placeholder="stringList.type_select_item"
                    @select="
                      (value) => {
                        if (value === 'select') item.type = undefined;
                        else {
                          select(index, item);
                          itemsSearch();
                        }
                      }
                    "
                  >
                    <a-select-option value="select" key="select">
                      {{ stringList.type_select_item }}
                    </a-select-option>
                    <a-select-option
                      :value="item.id"
                      v-for="item in otherItemsList"
                      :key="item.id"
                    >
                      {{ item[language] || item.keyword }}
                    </a-select-option>
                    <div slot="dropdownRender" slot-scope="menu">
                      <v-nodes :vnodes="menu" />
                    </div>
                  </a-select>
                  <div
                    v-if="formData.items.length > 1"
                    @click="reduceItems(index)"
                    style="margin-left: 4px; display: flex"
                  >
                    <svg-icon
                      style="width: 14px; height: 1.01px"
                      class="photograph"
                      iconClass="reduce"
                      className="icons/svg/reduce.svg"
                    ></svg-icon>
                  </div>
                </div>

                <div
                  class="icon-button"
                  :class="
                    !!(item.errorType == 2 || item.errorType == 4)
                      ? 'has-error-item'
                      : ''
                  "
                  @click="clickOpen(4, ['items', index, 'photo'])"
                >
                  <svg-icon
                    v-if="!(item.errorType == 2 || item.errorType == 4)"
                    class="photograph"
                    iconClass="photograph_normal"
                    className="icons/svg/photograph_normal.svg"
                  ></svg-icon>
                  <svg-icon
                    v-else
                    class="photograph"
                    iconClass="photograph_error"
                    className="icons/svg/photograph_error.svg"
                  ></svg-icon>
                  <span
                    class="little-icon"
                    :style="
                      item.photo?.length == 1 ? 'width:14px;height:14px' : ''
                    "
                    v-if="item.photo?.length >= 1"
                    >{{
                      item.photo?.length > 1 ? item.photo?.length : ""
                    }}</span
                  >
                </div>
                <div
                  class="icon-button"
                  :class="
                    !!(item.errorType == 3 || item.errorType == 4)
                      ? 'has-error-item'
                      : ''
                  "
                  @click="clickOpen(3, ['items', index, 'message'])"
                >
                  <svg-icon
                    v-if="!(item.errorType == 3 || item.errorType == 4)"
                    class="edit"
                    iconClass="edit_normal"
                    className="icons/svg/edit_normal.svg"
                  ></svg-icon>
                  <svg-icon
                    v-else
                    class="edit"
                    iconClass="edit_error"
                    className="icons/svg/edit_error.svg"
                  ></svg-icon>
                  <span
                    class="little-icon"
                    style="width: 14px; height: 14px"
                    v-if="item.message"
                  ></span>
                </div>
              </a-form-item>
            </div>
            <div class="add-damages">
              <svg-icon
                @click="addItems()"
                style="width: 14px; height: 14px"
                iconClass="add"
                className="icons/svg/add.svg"
              ></svg-icon>
              <div class="text" @click="addItems()">{{ stringList.items }}</div>
            </div>
            <div
              v-for="(item, index) of formData.otherItems"
              class="items other-items-type"
              :key="index"
            >
              <a-form-item
                class="item list-item"
                :class="item.errorType == 2 ? 'photo-error' : ''"
                :label="getLabel('other_items', index)"
                :help="item.errorMessage"
                :validate-status="item.errorType != 0 ? 'error' : ''"
              >
                <div
                  class="icon-button required"
                  :class="
                    !!(item.errorType == 2 || item.errorType == 4)
                      ? 'has-error-item'
                      : ''
                  "
                  @click="clickOpen(4, ['otherItems', index, 'photo'])"
                >
                  <svg-icon
                    v-if="!(item.errorType == 2 || item.errorType == 4)"
                    class="photograph"
                    iconClass="photograph_normal"
                    className="icons/svg/photograph_normal.svg"
                  ></svg-icon>
                  <svg-icon
                    v-else
                    class="photograph"
                    iconClass="photograph_error"
                    className="icons/svg/photograph_error.svg"
                  ></svg-icon>
                  <span
                    class="little-icon"
                    :style="
                      item.photo?.length == 1 ? 'width:14px;height:14px' : ''
                    "
                    v-if="item.photo?.length >= 1"
                    >{{
                      item.photo?.length > 1 ? item.photo?.length : ""
                    }}</span
                  >
                </div>
                <div
                  class="icon-button"
                  :class="
                    !!(item.errorType == 3 || item.errorType == 4)
                      ? 'has-error-item'
                      : ''
                  "
                  @click="clickOpen(3, ['otherItems', index, 'message'])"
                >
                  <svg-icon
                    v-if="!(item.errorType == 3 || item.errorType == 4)"
                    class="edit"
                    iconClass="edit_normal"
                    className="icons/svg/edit_normal.svg"
                  ></svg-icon>
                  <svg-icon
                    v-else
                    class="edit"
                    iconClass="edit_error"
                    className="icons/svg/edit_error.svg"
                  ></svg-icon>
                  <span
                    class="little-icon"
                    style="width: 14px; height: 14px"
                    v-if="item.message"
                  ></span>
                </div>
              </a-form-item>
              <div style="width: 100%; margin-bottom: 11px">
                <a-textarea
                  class="text-area"
                  :class="
                    item.errorType != 0 && !item.type
                      ? 'damages-item-error'
                      : ''
                  "
                  :placeholder="stringList.other_items50"
                  :rows="4"
                  v-model="item.type"
                  @input="
                    checkInput(item.type, index, 'otherItems');
                    submitV();
                  "
                />
                <div class="error-message">{{ item.errorMoreMessage }}</div>
              </div>
            </div>
          </div>
        </template>
      </a-form>
      <div class="content-title" v-if="commitType == 'RS'">
        {{ stringList.ReplenishSupplies }}
      </div>
      <template v-if="commitType == 'RS'">
        <div>
          <a-collapse
            :activeKey="activeKey"
            :bordered="false"
            expandIconPosition="right"
          >
            <div slot="expandIcon">
              <!-- class="label-setting" -->
              <svg-icon
                class="coarrow"
                style="width: 12px; height: 6px; transform: rotate(180deg)"
                iconClass="arrow"
                className="icons/svg/arrow.svg"
              ></svg-icon>
            </div>
            <a-collapse-panel
              v-for="(item, index) of Rslist"
              :key="String(index)"
              :header="
                language == 'en'
                  ? item.title
                  : language in item
                  ? item[language]
                  : item.title
              "
            >
              <!-- <a-collapse-panel v-for="(item,index) of Rslist" :key="String(index)" :header="language=='en'?item.title:''"> -->
              <!-- {{ it[language] }} -->
              <div class="tag-box">
                <template v-for="it of item.children">
                  <div
                    @touchstart="touchStart($event, it)"
                    @touchend="touchEnd($event, it)"
                    :key="it.index"
                  >
                    <!-- {{it.color}} -->
                    <a-checkable-tag
                      :style="`${
                        it.color
                          ? 'background: #FF6600;color: #fff;font-weight:700;'
                          : ''
                      };`"
                      class="tag-btn"
                      v-if="it.inWork == 0"
                      :key="it.index"
                      v-model="it.checked"
                    >
                      {{
                        language == "en"
                          ? it.keyword.slice(5)
                          : language in it
                          ? it[language].slice(5)
                          : it.keyword.slice(5)
                      }}</a-checkable-tag
                    >
                    <a-checkable-tag
                      class="tag-btn ant-checkbox-disabled"
                      v-else
                      >{{
                        language == "en"
                          ? it.keyword.slice(5)
                          : it[language].slice(5)
                      }}</a-checkable-tag
                    >
                  </div>
                </template>
              </div>
            </a-collapse-panel>
            <!-- <a-collapse-panel key="2" header="This is panel header 2">
              <p> text </p>
            </a-collapse-panel>
            <a-collapse-panel key="3" header="This is panel header 3">
              <p> text </p>
            </a-collapse-panel> -->
          </a-collapse>
        </div>
      </template>
    </div>
    <div class="footer">
      <van-button
        :spinning="true"
        class="button"
        @click="submit"
        native-type="submit"
        >{{ stringList.submit
        }}<a-spin style="margin-left: 12px" v-if="indicator">
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 24px"
            spin /></a-spin
      ></van-button>
    </div>
    <div class="foot">
      {{ hotelName }}
    </div>
    <a-modal
      v-model="visible"
      :style="{ display: !modalShow ? 'none' : '' }"
      :title="null"
      :footer="null"
      :closable="false"
      :width="320"
      :maskClosable="type != 1"
      wrapClassName="all-modal"
      :bodyStyle="{ 'border-radius': '16px', background: '#fff' }"
      @cancel="visible = false"
    >
      <svg-icon
        v-if="type == 4"
        class="modal-delete_x ceshi"
        style="width: 14px; height: 14px; fill: #767676"
        iconClass="delete_x"
        className="icons/svg/delete_x.svg"
        @click="visible = false"
      ></svg-icon>
      <svg-icon
        v-if="type != 1 && type != 6 && type != 4 && type != 5"
        class="modal-delete_x ceshi"
        style="width: 14px; height: 14px; fill: #767676"
        iconClass="delete_x"
        className="icons/svg/delete_x.svg"
        @click="visible = false"
      ></svg-icon>
      <room-content
        v-if="type == 1"
        :room-number.sync="roomNumber"
        :room-array="roomArray"
        :room-type.sync="roomType"
        @submit="setRoom"
      />
      <setting-content v-if="type == 2">
        <template #user>
          <svg-icon
            style="width: 36px; height: 36px"
            iconClass="profile"
            className="icons/svg/profile.svg"
          ></svg-icon>
        </template>
        <svg-icon
          style="width: 2rem; height: 2rem"
          iconClass="globe"
          className="icons/svg/globe.svg"
        ></svg-icon>
      </setting-content>
      <!-- 编辑 -->
      <comments-content
        v-if="type == 3"
        :value.sync="getValue"
        @close="visible = false"
        @submitV="submitV()"
      ></comments-content>
      <!-- 拍照 -->
      <photograph-content
        ref="photo"
        v-if="type == 4"
        :value.sync="getValue"
        @closed="closed"
        @open="visible = true"
        @submitV="submitV()"
        @over="over($event)"
      >
        <template #left>
          <svg-icon
            style="width: 11.5px; height: 22px; transform: rotate(180deg)"
            iconClass="right"
            className="icons/svg/right.svg"
          ></svg-icon>
        </template>
        <template #right>
          <svg-icon
            style="width: 11.5px; height: 22px"
            iconClass="right"
            className="icons/svg/right.svg"
          ></svg-icon>
        </template>
        <template #submit>
          <svg-icon
            style="width: 0.875rem; height: 0.875rem"
            iconClass="add_white"
            className="icons/svg/add_white.svg"
          ></svg-icon>
          <svg-icon
            style="width: 1.75rem; height: 1.5rem; margin-left: 10px"
            iconClass="photograph_white"
            className="icons/svg/photograph_white.svg"
          ></svg-icon>
        </template>
        <template #delete>
          <svg-icon
            style="width: 1.375rem; height: 1.375rem"
            iconClass="delete"
            className="icons/svg/delete.svg"
          ></svg-icon> </template
      ></photograph-content>
      <CancelContent
        v-if="type == 5"
        @cancel="type = 6"
        :commitType="commitType"
        @complete="cancel"
      ></CancelContent>
      <DetailContent
        v-if="type == 6"
        @cancel="type = 5"
        @complete="
          reset();
          roomNumber = undefined;
        "
        :formData="formData"
        :label1="label1"
        :roomNumber="RoomNumberName"
        :isRS="commitType == 'RS'"
      >
        <!-- style="width: 1.25rem; height: 0.9375rem" -->
        <svg-icon
          iconClass="complete1"
          className="icons/svg/complete1.svg"
        ></svg-icon>
      </DetailContent>
    </a-modal>
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import RoomContent from "@/components/CheckList/room.vue";
import SettingContent from "@/components/CheckList/setting.vue";
import CommentsContent from "@/components/CheckList/comments.vue";
import PhotographContent from "@/components/CheckList/photograph.vue";
import DetailContent from "@/components/CheckList/detail.vue";
import CancelContent from "@/components/CheckList/cancel.vue";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import { thisExpression } from "@babel/types";
import { throttle, debounce } from "@/utils/utils";
import router from "@/router";
export default {
  name: "Home",
  components: {
    RoomContent,
    SettingContent,
    CommentsContent,
    PhotographContent,
    CancelContent,
    DetailContent,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      hotelName: "",
      url: {
        saveRoom: "/adminApi/htRoom/save", //更新房间状态接口
        create: "/api/common/hotel/data/checkListOrder/create", //更新房间状态接口
        orderCreate: "api/common/hotel/data/multipleorder/create", //更新房间状态接口
        getLanguageList: "/api/common/hotel/data/dict/by_key/language", // 语言列表
        queryRoomNum: "/api/sys_admin/hotel/room/query2/", // 查询房间号列表
        hotelQuery: "/api/sys_admin/hotel/query",
        getCheckListKeyWords: "/adminApi/keywords/getCheckListKeyWords",
        getDept: "/api/common/hotel/data/dept/by_hotel",
        cancel: "/api/common/hotel/data/sub_order/cancel/",
        botkeyhotellistByRs: `/admins/hotel_keywords/listByRS`,
      },
      modalShow: false,
      visible: true, // 弹窗
      currentVisible: [],
      activeKey: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
      ],
      wait:false,
      type: 0, // 1 房间号 2 设置 3 编辑 4 拍照 5 取消 6 提交
      loading: false,
      formData: {},
      indicator: false,
      roomArray: [],
      roomNumber: undefined,
      roomType: "1",
      toiletList: [{ action: [] }],
      sinkList: [{ action: [] }],
      otherItemsList: [{ action: [] }],
      otherItem: "",
      isInput: false,
      isFocus: false,
      labelList: [],
      label1: [],
      label11: [],
      label2: [],
      dept: [],
      subOrderIds: "",
      isOver: false,
      isSubmit: false,
      form: this.$form.createForm(this, { name: "checklist" }),
      commitType: Vue.ls.get("type"),
      cateList: [],
      userName: "",
      Rslist: [],
      validateStatus: "success",
      errorMsg: "",
      lastNumber: "",
    };
  },
  watch: {
    roomNumber: {
      handler(value) {
        if (value)
          if (
            this.roomArray.length > 0 &&
            this.roomArray.findIndex((item) => item.name == this.roomNumber) ==
              -1
          ) {
            // console.log("room error")
            this.validateStatus = "error";
            this.errorMsg = this.stringList.invalid_room;
          } else {
            this.validateStatus = "success";
            this.errorMsg = "";
          }
      },
      //  immediate: true,
    },
    language() {},
  },
  computed: {
    ...mapGetters({
      language: "language",
      languageList: "languageList",
      stringList: "stringList",
      roomList: "roomList",
    }),
    loginButton() {
      return this.stringList.log_in;
    },
    currentErrorMessage() {
      return {
        username: this.stringList.incorrect_name,
        password: this.stringList.incorrect_password,
      };
    },
    RoomNumberName() {
      return String(
        this.roomArray.find((item) => item.name == this.roomNumber)?.name || ""
      );
    },
    getValue: {
      get() {
        let value = { ...this.formData };
        this.currentVisible?.forEach((element) => {
          value = value[element];
        });
        if (this.currentVisible.length == 0) {
          value = "";
        }
        return value;
      },
      set(value) {
        // console.log(value);
        const setLocalDataByKey = (data, keyList, index) => {
          if (
            (keyList?.length > index) &
            (Object.keys(data).indexOf(String(keyList[index])) != -1)
          ) {
            data[keyList[index]] = setLocalDataByKey(
              data[keyList[index]],
              keyList,
              ++index
            );
            return data;
          } else {
            data = value;
            return data;
          }
        };
        this.formData = setLocalDataByKey(
          this.formData,
          this.currentVisible,
          0
        );

        if (this.currentVisible[this.currentVisible.length - 1] == "photo")
          setTimeout(() => {
            this.submitV();
          }, 2000);
        else {
          this.submitV();
        }
      },
    },
  },
  created() {
    // eslint-disable-next-line no-undef
    gtag("js", new Date());
    // eslint-disable-next-line no-undef
    gtag("config", "G-5LKWZNJ4KN");
    let roomNumber = Vue.ls.get("roomNumber");
    this.formData = this.getDefaultFormData();
    if (this.$route.params.roomNumber) {
      this.roomNumber = this.$route.params.roomNumber || undefined;
      Vue.ls.set("roomNumber", this.roomNumber, 60 * 60 * 1000 * 12);
    } else if (roomNumber) {
      this.roomNumber = roomNumber;
    }
    this.getLanguageList();
    this.$store.dispatch("user/setUserInfo", () => {
      this.hotelName = Vue.ls.get("hotelName");
      this.userName = Vue.ls.get("userName");
      this.getLabelList();
      this.getDepart();
      // debugger
      // setTimeout(()=>{
      if (this.commitType == "RS") {
        this.getRSList();
        this.getCate();
      }

      // },200)
    });
    let language = localStorage.getItem("language");
    this.$store.dispatch("editLanguage", language || "en");
    // setTimeout(() => {

    // }, 1500);
    this.getRoomArray();
    // this.visible = false
  },
  methods: {
    touchStart(event, item) {
      console.log(111111111, item);
      item.color = "#FF6600";
      this.Rslist = { ...this.Rslist };
    },
    touchEnd(event, item) {
      console.log(2222222, item);
      delete item.color;
      this.Rslist = { ...this.Rslist };
    },
    back() {
      this.$router.back();
    },
    getRSList() {
      // this.$store.dispatch("user/setUserInfo", () => {
      let form = {
        hotelId: this.$store.state.user.userInfo.hotelId,
        current: 1,
        size: 999,
        inUse:1,
        // requestSupply: 1,
        categoryNotEmpty: 1,
      };
      postAction(`${this.url.botkeyhotellistByRs}`, form).then((res) => {
        postAction(`/api/sys_admin/dict/query`, {
          key: "supplyCategory",
          pageNum: 1,
          pageSize: 999,
        })
          .then((response) => {
            this.cateList = response.data.list;
            // this.Rslist = res.data.records;
            const keyList = res.data.records.map((item) => item.category);
            console.log(keyList);
            this.Rslist =  Object.values([...new Set(keyList)].map((item) => {
              const it = this.cateList.find((it) => it.type == item);
              if (it.value[0] == "{")
                return {
                  title: item,
                  ...JSON.parse(it.value),
                  children: res.data.records.filter(
                    (it) => it.category == item
                  ),
                };
              else
                return {
                  title: item,
                  children: res.data.records.filter(
                    (it) => it.category == item
                  ),
                };
            }));
            console.log(this.Rslist);
          })
          .catch((err) => {
            console.log(err);
            if (err.status == 204) {
              this.cateList = [];
            }
          });
        // console.log(111111111111,res,this.Rslist)
      });
      // });
    },
    getCate() {},
    postRs() {
      let frontOfficeId = this.dept.find(
        (item) => item.deptName == "FrontOffice"
      )?.hotelDeptId;
      let engineeringId = this.dept.find(
        (item) => item.deptName == "Engineering"
      )?.hotelDeptId;
      const requestTime = new Date();
      console.log(this.Rslist, 11111);
      const request = [];
      this.formData.RS = {};
      Object.values(this.Rslist).forEach((item) => {
        item.children.forEach((it) => {
          if (it.checked && it.inWork == "0") {
            const cate =
              this.language == "en"
                ? item.title
                : this.language in item
                ? item[this.language]
                : item.title;
            if (!this.formData.RS[cate]) this.formData.RS[cate] = [];
            const value = { ...it };
            value.keyword =
              this.language == "en"
                ? it.keyword
                : this.language in it
                ? it[this.language]
                : it.keyword;
            this.formData.RS[cate].push(value);
            request.push({
              // "hotelDeptId":it.hotelId,
              hotelDeptId: this.dept.find((i) => i.deptName == it.dept)
                ?.hotelDeptId,
              orderType: "internal",
              requestTime: requestTime,
              roomNumber: this.RoomNumberName,
              serviceKey: it.keyword,
              requestSupply: true,
              keywordsId: it.id,
            });
          }
        });
      });
      if (!request.length) {
        this.$message.info({
          content: this.stringList.Pleasechooseitem,
          icon: () => null,
          // duration: 0,
        });
        return;
      }

      // this.formData.RS = this.Rslist;
      // return
      const params = { ticket: { requests: request } };
      postAction(`${this.url.orderCreate}`, params).then((res) => {
        if (res.status == "0") {
          this.type = 6;
          this.visible = true;
          this.modalShow = true;
          this.subOrderIds = res.msg;
        } else {
          this.$message.info({
            content: res.msg,
            icon: () => null,
            // duration: 0,
          });
        }
      });
    },
    over(isOver) {
      // console.log(22222222, isOver);
      this.isOver = isOver;
    },
    getDefaultFormData() {
      return {
        water: {
          num: 0,
          photo: [],
          message: "",
          errorType: "",
          errorMessage: "",
        },
        pet: {
          type: 0,
          photo: [],
          message: "",
          errorType: 0,
          errorMessage: "",
        },
        smoking: {
          type: 0,
          photo: [],
          message: "",
          errorType: "",
          errorMessage: "",
        },
        damages: [
          {
            description: "",
            photo: [],
            message: "",
            errorType: "",
            errorMessage: "",
          },
        ],
        items2: [],
        toilet: {
          type: undefined,
          photo: [],
          message: "",
          errorType: "",
          errorMessage: "",
        },
        sink: {
          type: undefined,
          photo: [],
          message: "",
          errorType: "",
          errorMessage: "",
        },
        items: [
          {
            type: undefined,
            photo: [],
            message: "",
            errorType: "",
            errorMessage: "",
            isInput: false,
            isFocus: false,
          },
        ],
        otherItems: [
          {
            type: undefined,
            photo: [],
            message: "",
            errorType: "",
            errorMessage: "",
            isInput: false,
            isFocus: false,
          },
        ],
      };
    },
    focusInput(item) {
      setTimeout(() => {
        // console.log(item.isFocus);
        if (!item.isFocus) {
          item.isInput = false;
        } else {
          item.isFocus = false;
          item.isInput = true;
          // console.log(11111, item);
        }
        item = { ...item };
      }, 200);
    },
    focusSelect(item) {
      this.formData.otherItems.forEach((it) => {
        it.isInput = false;
      });
      item.isFocus = false;
      // debugger
      if (this.labelList.findIndex((it) => it.id == item.type) == -1) {
        // console.log(1111111111111)
        this.otherItem = item.type;
      }
      // console.log(item);
      item.isInput = true;
      item = { ...item };
    },
    select(index, item) {
      // console.log(this.$refs["selectItem" + index]);
      this.$refs["selectItem" + index][0].blur();
      item.isInput = false;
      this.otherItem = "";
      item.isFocus = false;
      this.formData.sink.selectErrorType == 5 ? this.submitV() : "";
      this.formData = { ...this.formData };
    },
    getLabel(label, index) {
      if (index == 0) {
        return this.stringList[label];
      } else {
        return this.stringList[label + 1].replace("1", index);
      }
    },
    getLabelList() {
      getAction(
        `${this.url.getCheckListKeyWords}?hotelId=${this.$store.state.user.userInfo.hotelId}`
      ).then((res) => {
        if (res.status == 0) {
          this.sinkList = [];
          this.toiletList = [];
          this.otherItemsList = [];
          this.label1 = [];
          this.label11 = [];
          this.label2 = [];
          this.labelList = res.data;
          res.data.forEach((item) => {
            // if(item.label)console.log(JSON.parse(item.label).en,JSON.parse(item.action),1111111111111111)
            // if (item.label && JSON.parse(item.label).en == "Sink") {
            //   item.action = JSON.parse(item.action);
            //   this.sinkList.push(item);
            // } else if (item.label && JSON.parse(item.label).en == "Toilet") {
            //   item.action = JSON.parse(item.action);
            //   this.toiletList.push(item);
            // }else
            if (item.hkFlag == 3 && item.keyword) {
              this.otherItemsList.push(item);
            } else if (item.hkFlag == 1) {
              this.label1.push(item);
              if(item.keyword!="Smoking/Vaping"&&item.keyword!="Pet in Room"&&item.keyword!="Bottled Water"){
                this.label11.push(item);
                this.formData[item.id] = JSON.parse(JSON.stringify(this.formData.pet))
              }
            }
            if (item.hkFlag == 2) {
              this.label2.push(item);
            }
          });
          console.log(this.label1);
          this.sortArrByKeyword(this.otherItemsList);
          this.setItems2();
          this.sinkList = [...new Set(this.sinkList)];
          this.toiletList = [...new Set(this.toiletList)];
          Vue.ls.set("labelList", this.labelList);
        }
      });
    },
    setItems2() {
      let labelList = [];
      const findLabel = (label) => {
        // console.log(
        //   labelList.findIndex((item) => {
        //     return item.label.en == label.en;
        //   })
        // );
        return labelList.findIndex((item) => {
          return item.label.en == label.en;
        });
      };
      this.label2.forEach((it) => {
        if (it.action && typeof it.action == "string") {
          it.action = JSON.parse(it.action);
        }
        // console.log(it);
        if (it.label != "" && findLabel(JSON.parse(it.label)) != -1) {
          labelList[findLabel(JSON.parse(it.label))].select.push(it);
        } else {
          labelList.push({
            label: it.label ? JSON.parse(it.label) : "",
            select: [it],
            type: it.action ? undefined : 0,
            noSelectType: it.action ? undefined : it.keyword,
            photo: [],
            message: "",
            errorType: "",
            errorMessage: "",
          });
        }
      });
      this.formData.items2 = labelList;
      // console.log(this.formData);
    },
    sortArrByKeyword(arr) {
      arr.sort((a, b) => {
        if (
          (a[this.language] || a.keyword)[0].toLowerCase() <
          (b[this.language] || b.keyword)[0].toLowerCase()
        ) {
          return -1;
        }
        if (
          (a[this.language] || a.keyword)[0].toLowerCase() >
          (b[this.language] || b.keyword)[0].toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });
      // console.log(arr);
      return arr;
    },
    itemsSearch(value, index) {
      let keyItem;
      // console.log(value, index, this.labelList);
      if (value) {
        this.otherItemsList = this.labelList.filter((item) => {
          return (
            item.hkFlag == 3 &&
            item.keyword &&
            ((this.language == "en" &&
              item.keyword.toUpperCase().indexOf(value.toUpperCase()) != -1) ||
              !!(
                item[this.language] && item[this.language].indexOf(value) != -1
              ))
          );
        });
        this.otherItemsList.forEach((item, idx) => {
          if (
            (this.language == "en" &&
              item.keyword.toUpperCase() == value.toUpperCase()) ||
            !!(
              item[this.language] &&
              item[this.language].toUpperCase() == value.toUpperCase()
            )
          ) {
            keyItem = this.otherItemsList[idx];
            this.otherItemsList.splice(idx, 1);
          }
        });
        // console.log(this.otherItemsList);
        if (this.otherItemsList.length == 1) {
          // console.log(
          //   11111,
          //   this.$refs["selectItem" + index],
          //   this.$refs["selectItem" + index][0].$el,
          //   this.otherItemsList[0].id
          // );
          this.formData.items[index].type = this.otherItemsList[0].id;
          this.$refs["selectItem" + index][0].$el.click();
          this.otherItemsList = this.labelList.filter((item) => {
            return item.hkFlag == 3 && item.keyword;
          });
        }
      } else {
        this.otherItemsList = this.labelList.filter((item) => {
          return item.hkFlag == 3 && item.keyword;
        });
      }
      this.otherItemsList = this.sortArrByKeyword(this.otherItemsList);
      if (keyItem) this.otherItemsList.unshift(keyItem);
    },
    getDepart() {
      getAction(
        `${this.url.getDept}/${this.$store.state.user.userInfo.hotelNo}`
      ).then((res) => {
        if (res.status == 0) {
          this.dept = res.data;
        }
      });
    },
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
    closed() {
      this.visible = false;
      this.submitV();
    },
    addDamages() {
      let hasError = false;
      this.formData.damages.forEach((item) => {
        if (item.photo.length == 0) {
          hasError = true;
          item.errorType = 2;
          item.errorMessage = this.stringList.photo_required;
          // if (!item.message) {
          //   item.errorType = 4;
          //   item.errorMessage = this.stringList.photo_comments;
          // }
        }
        // else if (!item.message) {
        //   hasError = true;
        //   item.errorType = 3;
        //   item.errorMessage = this.stringList.comments_required;
        // }
        else {
          item.errorType = 0;
          item.errorMessage = "";
          if (!item.description) {
            item.errorType = 5;
            hasError = true;
          }
        }
      });
      if (hasError) return;
      this.formData.damages.push({
        description: "",
        photo: [],
        message: "",
        errorType: "",
        errorMessage: "",
      });
    },
    reduceDamages(index) {
      this.currentVisible = [];
      this.formData.damages.splice(index, 1);
    },
    addItems() {
      let hasError = false;
      this.formData.items.forEach((item) => {
        item.selectErrorType = 0;
        item.errorMessage = "";
        if (!item.type) {
          item.selectErrorType = 5;
          hasError = true;
        }
        // console.log(item);
      });
      this.formData = { ...this.formData };
      if (hasError) return;
      this.formData.items.push({
        type: undefined,
        photo: [],
        message: "",
        errorType: "",
        errorMessage: "",
        isInput: false,
        isFocus: false,
      });
    },
    reduceItems(index) {
      this.currentVisible = [];
      this.formData.items.splice(index, 1);
    },
    checkInput(value, index, type = "damages") {
      if (value.length > 50) {
        this.formData[type][index].description = value.slice(0, 50);
        if (type != "damages")
          this.formData[type][index].type = value.slice(0, 50);
        this.formData[type][index].errorMoreMessage = this.stringList["50code"];

        // this.$message.info({
        //   content: this.stringList["50code"],
        //   key: "50code",
        //   icon: () => {
        //     return "";
        //   },
        //   // duration: 500,
        // });
      } else {
        this.formData[type][index].errorMoreMessage = "";
      }
    },
    getRoomArray() {
      getAction(`${this.url.queryRoomNum}${"guest"}/${"allmeeting"}`).then(
        (res) => {
          if (res.status == 0) {
            this.roomArray = res.data;
            if (!this.roomNumber) return;
            if (
              this.roomArray.findIndex(
                (item) => item.name == this.roomNumber
              ) == -1
            ) {
              this.validateStatus = "error";
              this.errorMsg = this.stringList.invalid_room;
              if (this.commitType != "RS") {
                // this.type = 1;
                // this.visible = true;
              }
            }
          }
        }
      );
    },
    clickOpen(type, nameList) {
      if (this.isOver) {
        return;
      }

      if (nameList) {
        this.currentVisible = nameList;
      } else {
        this.currentVisible = [];
      }
      // console.log(type,this.formData[name],nameList);
      this.type = type;
      this.visible = true;
      this.modalShow = true;
      // console.log(type, nameList, this.getValue);
      if (type == 4 && this.getValue.length == 0) {
        this.modalShow = false;
        // this.isOver = true;
        this.visible = false;
        this.$nextTick(() => {
          this.$refs.photo.uploadImg();
        });
      }
      // setTimeout(()=>{
      // this.getValue = ["aaa"]
      // })
    },
    setRoom(param) {
      this.roomNumber = param.roomNumber;
      this.roomType = param.roomType;
      let params = {
        id: this.roomNumber,
        status:
          this.roomType == 1
            ? this.stringList.vacant_checked_out
            : this.stringList.occupied_stay_over,
        // value: this.formData,
      };
      postAction(`${this.url.saveRoom}`, params)
        .then((res) => {
          this.indicator = false;
          if (res.status == 0) {
            Vue.ls.set("roomNumber", this.roomNumber, 60 * 60 * 1000 * 12);
            this.visible = false;
          }
        })
        .catch((err) => {});
    },
    getHotelInfo() {
      postAction(this.url.queryHotel, {
        hotelNo: this.$route.query.inno,
      }).then((res) => {
        // console.log(res);
      });
    },
    onSubmit(values) {
      // console.log("submit", values, this.loginParams);
      this.$router.push({ name: "CheckList" });
    },
    submitV(i) {
      console.log("submitV");
      let hasError = false;
      let formData = { ...this.formData };
      for (const key in formData) {
        if (Object.hasOwnProperty.call(this.formData, key)) {
          const element = this.formData[key];
          if (key == "water") {
            if (!element.num) {
              if (element.photo.length > 0 || element.message) {
                hasError = true;
                element.selectErrorType = 5;
                element.selectErrorMessage = "";
              } else {
                element.selectErrorType = 0;
                element.selectErrorMessage = "";
              }
            } else {
              element.selectErrorType = 0;
              element.selectErrorMessage = "";
            }
          } else if (key == "pet"||this.label11.find(item=>item.id==key)) {
            const petV = () => {
              if (element.photo.length == 0) {
                hasError = true;
                element.errorType = 2;
                element.errorMessage = this.stringList.photo_required;
              } else {
                element.errorType = 0;
                element.errorMessage = "";
              }
            };
            if (element.type) {
              petV();
              element.selectErrorType = 0;
              element.selectErrorMessage = "";
            } else {
              if (element.photo.length > 0 || element.message) {
                hasError = true;
                element.selectErrorType = 5;
                element.selectErrorMessage = "";
                petV();
              } else {
                element.selectErrorType = 0;
                element.selectErrorMessage = "";
                element.errorType = 0;
                element.errorMessage = "";
              }
            }
          } else if (key == "smoking") {
            const smokingV = () => {
              if (element.photo.length == 0) {
                hasError = true;
                element.errorType = 2;
                element.errorMessage = this.stringList.photo_required;
                if (!this.formData[key].message) {
                  element.errorType = 4;
                  element.errorMessage = this.stringList.photo_comments;
                }
              } else if (!element.message) {
                hasError = true;
                element.errorType = 3;
                element.errorMessage = this.stringList.comments_required;
              } else {
                element.errorType = 0;
                element.errorMessage = "";
              }
            };
            if (element.type) {
              smokingV();
              element.selectErrorType = 0;
              element.selectErrorMessage = "";
            } else {
              if (element.photo.length > 0 || element.message) {
                hasError = true;
                element.selectErrorType = 5;
                element.selectErrorMessage = "";
                smokingV();
              } else {
                element.selectErrorType = 0;
                element.selectErrorMessage = "";
                element.errorType = 0;
                element.errorMessage = "";
              }
            }
          } else if (key == "items" || key == "items2") {
            element.forEach((item) => {
              if (item.description || item.type) {
                item.selectErrorType = 0;
                item.selectErrorMessage = "";
              } else {
                if (item.photo.length || item.message) {
                  hasError = true;
                  item.selectErrorType = 5;
                  item.selectErrorMessage = "";
                  // if (item.noSelectType) {
                  //   hasError = false;
                  //   item.selectErrorType = 0;
                  //   item.selectErrorMessage = "";
                  // }
                } else {
                  item.selectErrorType = 0;
                  item.selectErrorMessage = "";
                  item.errorType = 0;
                  item.errorMessage = "";
                }
              }
            });
          } else if (key == "damages" || key == "otherItems") {
            element.forEach((item) => {
              const daItV = () => {
                if (item.photo.length == 0) {
                  hasError = true;
                  item.errorType = 2;
                  item.errorMessage = this.stringList.photo_required;
                  // if (!item.message) {
                  //   item.errorType = 4;
                  //   item.errorMessage = this.stringList.photo_comments;
                  // }
                }
                // else if (!item.message) {
                //   hasError = true;
                //   item.errorType = 3;
                //   item.errorMessage = this.stringList.comments_required;
                // }
                else {
                  item.errorType = 0;
                  item.errorMessage = "";
                  if (key == "otherItems" && !item.type) {
                    hasError = true;
                    item.errorType = 5;
                  }
                  if (key == "damages" && !item.description) {
                    hasError = true;
                    item.errorType = 5;
                  }
                }
              };
              if (item.description || item.type) {
                item.selectErrorType = 0;
                item.selectErrorMessage = "";
                daItV();
              } else {
                if (item.photo.length || item.message) {
                  hasError = true;
                  item.selectErrorType = 5;
                  item.selectErrorMessage = "";
                  daItV();
                } else {
                  item.selectErrorType = 0;
                  item.selectErrorMessage = "";
                  item.errorType = 0;
                  item.errorMessage = "";
                }
              }
            });
          } else {
            if (
              (element.photo.length > 0 || element.message) &&
              !element.type
            ) {
              hasError = true;
              element.selectErrorType = 5;
              element.selectErrorMessage = "";
            } else {
              element.selectErrorType = 0;
              element.selectErrorMessage = "";
              element.errorType = 0;
              element.errorMessage = "";
            }
          }
        }
      }
      this.formData = { ...formData };
      if (hasError == true) {
        this.indicator = false;
        // console.log("存在错误!");
        return false;
      }
      return true;
    },
    submit() {
      if(this.wait)return;
      this.wait = true;
      setTimeout(()=>{
        this.wait = false;
      },10000)
      if (this.commitType == "RS") {
        // console.log(1111111111);

        this.postRs();
        return;
      }
      if (!this.roomNumber) {
        this.validateStatus = "error";
        this.errorMsg = this.stringList["select_enter_room#"];
        document.querySelector(".home").scrollTo(0, 0);
        return;
      }
      this.indicator = true;
      this.isSubmit = true;
      if (!this.submitV()) return;
      // this.visible = true;
      // this.type = 6;
      let params = [];
      // let params = {
      //   id: this.roomNumber,
      //   status: String(this.roomType),
      //   // value: this.formData,
      // };
      let frontOfficeId = this.dept.find(
        (item) => item.deptName == "FrontOffice"
      )?.hotelDeptId;
      let engineeringId = this.dept.find(
        (item) => item.deptName == "Engineering"
      )?.hotelDeptId;
      // console.log(frontOfficeId, engineeringId);
      let request = [],
        request1 = [];
      let requestTime =
        this.roomType != "1"
          ? new Date(new Date().getTime())
          : new Date(new Date().getTime());
      for (const key in this.formData) {
        if (Object.hasOwnProperty.call(this.formData, key)) {
          const element = this.formData[key];
          if (key == "damages") {
            this.formData[key].forEach((item) => {
              if (item.description) {
                request.push({
                  hotelDeptId: frontOfficeId,
                  img: item.photo?.map((i) => i.url).join(","),
                  inquiryNum: item.num || "",
                  notes: item.message,
                  requestTime: requestTime,
                  roomNumber: this.RoomNumberName,
                  serviceKey: item.description,
                });
              }
            });
          }
          if (key == "otherItems" || key == "items" || key == "items2") {
            this.formData[key].forEach((item) => {
              if (item.photo.length > 0 && item.noSelectType) {
                // console.log(111111111111, item.photo && item.noSelectType);
                let keyLabel = this.labelList.find(
                  (it) => it.keyword == item.noSelectType
                );
                // console.log(item);
                request1.push({
                  hotelDeptId: engineeringId,
                  img: item.photo?.map((i) => i.url).join(","),
                  inquiryNum: item.num || "",
                  notes: item.message,
                  requestTime:
                    this.roomType != "1"
                      ? new Date()
                      : new Date(new Date().getTime() + 4 * 60 * 60 * 1000),
                  roomNumber: this.RoomNumberName,
                  serviceKey: keyLabel.keyword,
                  serviceType: keyLabel.serviceType,
                });
              } else if (item.type) {
                // console.log(item.type);
                let service;
                let keyLabel = this.labelList.find((it) => it.id == item.type);
                // console.log(keyLabel);
                if (keyLabel) {
                  service = {
                    serviceKey: keyLabel.keyword,
                    serviceType: keyLabel.serviceType,
                  };
                } else {
                  service = {
                    serviceKey: item.type,
                  };
                }
                // params.push({
                //   ticket: {
                //     requests: [
                //       {
                //         hotelDeptId: engineeringId,
                //         img: item.photo?.map((i) => i.url).join(","),
                //         inquiryNum: item.num || "",
                //         notes: item.message,
                //         requestTime: requestTime,
                //         roomNumber: this.RoomNumberName,
                //         ...service,
                //       },
                //     ],
                //   },
                // });
                request1.push({
                  hotelDeptId: engineeringId,
                  img: item.photo?.map((i) => i.url).join(","),
                  inquiryNum: item.num || "",
                  notes: item.message,
                  requestTime:
                    this.roomType != "1"
                      ? new Date()
                      : new Date(new Date().getTime() + 4 * 60 * 60 * 1000),
                  roomNumber: this.RoomNumberName,
                  ...service,
                });
              }
            });
          } else {
            if (element.type || element.num) {
              let service = {};
              let keyLabel;
              switch (key) {
                case "pet":
                  keyLabel = this.label1.find(
                    (item) => item.keyword == "Pet in Room"
                  );
                  service = !keyLabel
                    ? {
                        serviceKey: "Pet in Room",
                        serviceType: "Service",
                      }
                    : {
                        serviceKey: keyLabel.keyword,
                        serviceType: keyLabel.serviceType,
                      };
                  if (
                    !this.label1.find((item) => item.keyword == "Pet in Room")
                  ) {
                    service = {
                      serviceKey: "Two Pets in Room",
                      serviceType: "Service",
                    };
                  }
                  break;
                case "water":
                  keyLabel = this.label1.find(
                    (item) =>
                      item.keyword == "Bottled Water" ||
                      item.keyword == "Bottled water"
                  );
                  service = !keyLabel
                    ? {
                        serviceKey: "Bottled Water",
                        serviceType: "Amenity",
                        // serviceKey: keyLabel.keyword,
                        // serviceType: keyLabel.serviceType,
                      }
                    : {
                        serviceKey: keyLabel.keyword,
                        serviceType: keyLabel.serviceType,
                      };
                  break;
                case "smoking":
                  keyLabel = this.label1.find(
                    (item) => item.keyword == "Smoking/Vaping"
                  );
                  service = !keyLabel
                    ? {
                        serviceKey: "Smoking/Vaping",
                        serviceType: "Service",
                        // serviceKey: keyLabel.keyword,
                        // serviceType: keyLabel.serviceType,
                      }
                    : {
                        serviceKey: keyLabel.keyword,
                        serviceType: keyLabel.serviceType,
                      };
                  break;
                case "toilet":
                  keyLabel = this.toiletList.find(
                    (item) => item.id == element.type
                  );
                  service = {
                    serviceKey: keyLabel.keyword,
                    serviceType: keyLabel.serviceType,
                  };
                  break;
                case "sink":
                  keyLabel = this.sinkList.find(
                    (item) => item.id == element.type
                  );
                  service = {
                    serviceKey: keyLabel.keyword,
                    serviceType: keyLabel.serviceType,
                  };
                  break;

                default:
                  keyLabel = this.label1.find(
                    (item) => item.id == key
                  );
                  service = {
                    serviceKey: keyLabel.keyword,
                    serviceType: keyLabel.serviceType,
                  };
                  break;
              }
              if (key == "sink" || key == "toilet") {
                // params.push({
                //   ticket: {
                //     requests: [
                //       {
                //         hotelDeptId: engineeringId,
                //         img: element.photo?.map((i) => i.url).join(","),
                //         inquiryNum: element.num || "",
                //         notes: element.message,
                //         requestTime: requestTime,
                //         roomNumber: this.RoomNumberName,
                //         ...service,
                //       },
                //     ],
                //   },
                // });
                request1.push({
                  hotelDeptId: engineeringId,
                  img: element.photo?.map((i) => i.url).join(","),
                  inquiryNum: element.num || "",
                  notes: element.message,
                  requestTime: requestTime,
                  roomNumber: this.RoomNumberName,
                  ...service,
                });
              } else {
                request.push({
                  hotelDeptId:
                    key == "pet" || key == "water" || key == "smoking"||!!this.label11.find(i=>i.id==key)
                      ? frontOfficeId
                      : engineeringId,
                  img: element.photo?.map((i) => i.url).join(","),
                  inquiryNum: element.num,
                  notes: element.message,
                  requestTime: requestTime,
                  roomNumber: this.RoomNumberName,
                  ...service,
                });
              }
            }
          }
        }
      }

      if (request.length > 0)
        params.push({
          ticket: {
            requests: request,
          },
        });
      if (request1.length > 0)
        params.push({
          ticket: {
            requests: request1,
          },
        });
      console.log(params,this.formData,this.label1);
      if (params.length > 0) {
        postAction(`${this.url.create}`, params)
          .then((res) => {
            this.indicator = false;
            if (res.status == 0) {
              this.visible = true;
              this.type = 6;
              this.modalShow = true;
              this.wait = false;
              // console.log(res);
              this.subOrderIds = res.msg;
            } else {
              // this.$message.error("网络错误");
            }
          })
          .catch((err) => {
            this.indicator = false;
            // this.visible = true;
            // this.type = 6;
          });
      } else {
        this.$message.info({
          content: this.stringList.please_choose_item,
          icon: () => null,
          // duration: 0,
        });
        this.indicator = false;
      }
    },
    reset() {
      Vue.ls.remove("roomNumber");
      this.roomType = "1";
      this.visible = false;
      this.isSubmit = false;
      this.formData = this.getDefaultFormData();
      this.label11.forEach(item=>{
        this.formData[item.id] = JSON.parse(JSON.stringify(this.formData.pet))
      })
      this.setItems2();
      if (this.commitType != "RRCM") {
        this.$router.push({
          name: "Home",
          // params: { roomNumber: roomNumber },
        });
      }
      // this.formData.sink.type = undefined;
      // this.formData.toilet.type = undefined;
    },
    cancel() {
      // if(this.commitType!="RS"){

      // }
      postAction(`${this.url.cancel + this.subOrderIds}`)
        .then((res) => {
          if (res.status == 0) {
            this.reset();
            this.roomNumber = undefined;
          } else {
            // this.$message.error("网络错误");
          }
        })
        .catch((err) => {
          // this.visible = true;
          // this.type = 6;
        });
    },
    getLanguageList() {
      getAction(this.url.getLanguageList).then((res) => {
        Vue.ls.set("languageList", res.data);
        this.editLanguageList(res.data);
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log("Received values of form: ", values);
        }
      });
    },
    ...mapActions([
      "editLanguageList",
      "editColor", //
      "editAmcniticsRequest",
      "editInformationRequest",
      "editShowPhone",
      "editRoomList",
      "login",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.home {
  text-align: left;
  // width: 100%;
  background: #f2f2f2;
  height: 100%;
  top: 0;
  // position: absolute;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  .title {
    display: flex;
    align-items: center;
    padding: 24px 20px;
    .label {
      min-width: 92px;
      height: 3rem;
      opacity: 1;
      font-family: Arial;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      display: flex;
      align-items: center;
      letter-spacing: 0em;
      word-break: break-all;
    }
    .label-setting {
      // margin-left: 26px;
      width: 1.5rem;
      height: 1.5rem;
    }
    .room-num /deep/.ant-select-selection {
      border-radius: 12px;
    }
    /deep/.ant-select-selection {
      height: 48px;
      border-radius: 12px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #afafaf;
      .ant-select-selection__placeholder {
        text-align: center;
      }
      .ant-select-selection__rendered {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
      }
    }
    .show-placeholder {
      /deep/.ant-select-selection__placeholder {
        display: block !important;
        width: 100%;
        // min-width: 80px;
      }
    }
  }
  .content {
    padding: 24px 1.75rem;
    // border-radius: 2.25rem;
    opacity: 1;
    // flex: 1;
    background: #ffffff;
    .content-title {
      height: 26px;
      opacity: 1;
      font-family: Arial;
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0em;
      color: #5a578c;
      margin-bottom: 32px;
    }
    /deep/.photo-error {
      .ant-form-explain {
        left: 90px !important;
        text-align: left !important;
        justify-content: flex-start !important;
      }
    }
    /deep/.select-error {
      .ant-select-selection__placeholder {
        color: #df714f !important;
      }
      .ant-select-selection,
      .ant-select-selection-selected-value {
        color: #df714f !important;
        background: #fee0d6 !important;
        border: 0 !important;
        .ant-select-arrow svg {
          fill: #df714f;
        }
      }
    }
    /deep/.list-item.photo-error {
      .ant-form-explain {
        left: 10px !important;
      }
    }
    /deep/.ant-select {
      margin-right: 8px;
    }
    .other-items-type{
      /deep/.ant-form-item-control-wrapper {
        width: auto;
        flex: 0 !important;
      }
      .photo-error{
        margin-bottom: 24px !important;
      }
    }

    .item {
      display: flex;
      margin: 24px 0 20px;
      // align-items: center;
      .ant-select {
        width: auto;
      }
      /deep/.has-error {
        .ant-form-explain {
          position: absolute;
          right: 0;
          left: -80px;
          text-align: right;
          opacity: 1;
          font-family: Arial;
          font-size: 14px;
          font-weight: 400;
          line-height: 18.72px;
          letter-spacing: 0em;
          display: flex;
          align-items: center;
          color: #df714f;
          justify-content: flex-end;
        }
        .ant-select-selection {
          box-shadow: none;
        }
      }
      /deep/.ant-select-selection {
        box-shadow: none;
        border-radius: 12px;
        .ant-select-arrow {
          color: #afafaf;
        }
      }
      .has-error-item.icon-button {
        border: 0;
        // background: #fee0d6;
      }
      /deep/.has-error-item {
        color: #df714f;
        .ant-select-selection {
          border: 0;
          background: #fee0d6;
          color: #df714f;
        }
        .ant-select-arrow {
          color: #df714f;
        }
      }
      .has-error-item::before {
        // border: 1px solid red;
        color: #df714f !important;
      }
      /deep/.ant-select-selection {
        justify-content: center;
        width: 80px;
        height: 48px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        opacity: 1;
        background: #ffffff;
        box-sizing: border-box;
        border: 1px solid #afafaf;
      }
      /deep/.ant-form-item-children {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      /deep/.ant-form-item-label {
        flex: 1;
        // width: 92px;
        min-width: 92px;
        // height: 48px;
        opacity: 1;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        letter-spacing: 0em;
        color: #3d3d3d;
        label {
          word-break: break-all;
          white-space: break-spaces;
          text-align: left;
          font-family: Arial;
          font-size: 16px;
          font-weight: 400;
          color: #101010;
        }
      }

      /deep/.ant-form-item-control-wrapper {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
      
      .required::before {
        // border: 1px solid red;
        content: "*";
        position: absolute;
        left: 8px;
        top: 18px;
        font-size: 16px;
        color: #636773;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
      }

      .icon-button {
        // margin-left: 10px;
        width: 4rem;
        height: 3rem;
        border-radius: 12px;
        opacity: 1;
        // background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .little-icon {
          position: absolute;
          top: 8px;
          right: 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          opacity: 1;
          font-family: Arial;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          background: #ff6600;
          border: 2px solid #ffffff;
        }
        .photograph {
          width: 1.75rem;
          height: 1.5rem;
        }
        .edit {
          width: 1.375rem;
          height: 1.375rem;
        }
      }
    }
    .damages-item-error {
      background: #fee0d6 !important;
      color: #df714f !important;
      border: 1px solid #df714f !important;
    }
    .damages-item-error::placeholder {
      color: #df714f !important;
    }
    .add-damages {
      // margin-top: 11px;
      display: flex;
      align-items: center;
      .text {
        margin-left: 12px;
        height: 22px;
        opacity: 1;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        letter-spacing: 0em;
        text-decoration: underline;
        color: #3d3d3d;
      }
    }
    /deep/.text-area-error {
      border: 1px solid #df714f;
    }
    .text-area {
      height: 72px;
      border-radius: 12px;
      opacity: 1;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #afafaf;
      padding: 15px 16px 11px;
      display: flex;
      align-items: center;
      resize: none;
    }
    .maintenance.items-num {
      .items {
        /deep/.ant-select {
          margin-right: 28px;
          // .ant-select-selection{
          //   width: 86px;
          // }
        }
      }
    }
    .maintenance {
      margin-top: 36px;
      padding: 3rem 1.75rem 2.3125rem;
      border-radius: 2.25rem;
      opacity: 1;
      background: #edf1f3;
      width: calc(100% + 56px);
      height: calc(100% + 56px);
      position: relative;
      left: -1.75rem;
      .items2 {
        /deep/.ant-select-selection__rendered {
          max-width: 80px;
        }
      }
      /deep/.ant-select-selection {
        width: 106px;
        .ant-select-selection__rendered {
          flex: 1;
        }
      }
      /deep/.select-item {
        border-radius: 12px;
        display: flex;
        .ant-select-selection {
          flex: 1;
          // width: 100%;
          height: 50px;
          border-radius: 12px;
          opacity: 1;
          background: #f9fafb;
          box-sizing: border-box;
          border: 1px solid #afafaf;
          .ant-select-selection__rendered {
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 100%;
            display: flex;
            overflow: hidden;
            align-items: center;
            margin-left: 17px;
          }
        }
      }
      /deep/.select-item-error {
        .ant-select-selection {
          border: 1px solid #df714f;
          background: #fee0d6;
          .ant-select-selection__placeholder {
            color: #df714f !important;
          }
          svg {
            fill: #df714f;
          }
        }
      }
    }
  }
}
.footer {
  margin: 52px auto 32px;
  .button {
    width: 200px;
    height: 48px;
    border-radius: 8px;
    opacity: 1;
    background: #ff6600;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: center;
    // display: flex;
    // align-items: center;
    letter-spacing: 0em;
    color: #ffffff;
    border: 0;
  }
}
/deep/.ant-modal-content {
  border-radius: 32px;
  .modal-delete_x {
    z-index: 10;
    position: absolute;
    right: 18px;
    top: 28px;
    width: 18px;
    height: 18px;
  }
}
/deep/.all-modal {
  display: flex;
  align-items: center;
  .ant-modal {
    padding: 0;
    top: 0;
  }
}

.other-input {
  border: 0;
  height: 48px !important;
  border-radius: 12px;
  opacity: 1;
  background: #edf1f3;
  font-family: Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.72px;
  display: flex;
  align-items: center;
  color: #101010;
}
.other-label {
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #3d3d3d;
  margin-bottom: 6.5px;
}
.other-divider {
  margin: 17px 0;
  height: 0px;
  background: #8e8f90;
  border-bottom: 1px solid #afafaf;
}
.save-other-button {
  width: 72px;
  height: 48px;
  border-radius: 12px;
  opacity: 1;
  margin-left: 4px;
  background: #ff6600;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
}
.error-message {
  opacity: 1;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #df714f;
}
.items {
  /deep/.ant-form-item-control {
    flex: 1;
  }
}
.small {
  .content-title {
    margin-bottom: 24px !important;
  }
  .item {
    margin: 16px 0 !important;
    /deep/.ant-select-selection {
      height: 42px !important;
    }
  }
  .damages-list {
    .item {
      margin-bottom: 0 !important;
    }
    .photo-error {
      margin-bottom: 24px !important;
    }
  }
  .damages-list{
      /deep/.ant-form-item-control-wrapper {
        width: auto;
        flex: 0 !important;
      }
    }
  .add-damages {
    margin-bottom: 23px !important;
  }
  .maintenance {
    margin-top: 0 !important;
    padding: 36px 28px 25px !important ;
  }
}
.footer {
  margin-top: 24px !important;
}
.less {
  padding-bottom: 16px !important;
}
.ant-collapse-item-active {
  .coarrow {
    transform: rotate(0) !important;
  }
}
/deep/.ant-collapse-header {
  opacity: 1;
  font-family: Arial;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  font-variation-settings: "opsz" auto;
  color: #5a578c !important;
}
/deep/.ant-collapse-header {
  border-bottom: 0.5px solid #e1e2e3;
}
/deep/.ant-collapse-item {
  border: none !important;
}
.tag-box {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px 12px;
  margin-top: 16px;
  .tag-btn {
    /* 自动布局子元素 */
    height: 100%;
    border-radius: 4px;
    opacity: 1;
    background: #ededed;
    z-index: 0;
    margin: 0;
    opacity: 1;
    font-family: Arial;
    font-size: 16px;
    font-variation-settings: "opsz" auto;
    color: #101010;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal;
    padding: 12px 16px;
    cursor: pointer;
    text-align: center;
  }
  /deep/ .ant-checkbox-disabled {
    background: #cbcbcb;
    color: #5f5f5f;
  }
  /deep/ .ant-checkbox-disabled:hover {
    background: #cbcbcb;
    color: #5f5f5f;
  }
  /deep/.ant-tag-checkable:not(.ant-checkbox-disabled):hover {
    // color: #000;
  }
  /deep/.ant-tag-checkable-checked {
    background: #ff6600;
    // color: #101010;
    color: #ffffff;
    font-weight: 700;
    // background: #CBCBCB;
    // color: #5F5F5F;
  }
  /deep/.ant-tag-checkable:not(.ant-checkbox-disabled):hover {
    // background: #FEAF80;
  }
}
.foot {
  // width: calc(100% - 110px);
  // position: absolute;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: 0em;
  font-variation-settings: "opsz" auto;
  color: #5b5b5b;
  margin-bottom: 12px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 300px;
  margin: auto;
  margin-bottom: 20px;
}
.username {
  // width: 240px;
  height: 48px;
  border-radius: 8px;
  opacity: 1;

  background: #ffffff;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0px;

  font-variation-settings: "opsz" auto;
  color: #333333;
  // padding: 0 30px;
  display: flex;
  justify-content: center;

  z-index: 1;
}
.room-type {
  // margin-top: 24px;
  .label {
    opacity: 1;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0em;
    color: #3d3d3d;
  }
  .ant-radio-group {
    display: flex;
    // margin-left: 23px;
    /deep/span:not(ant-radio) {
      opacity: 1;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      color: #3d3d3d;
      z-index: 1;
    }
    /deep/.ant-radio-inner {
      width: 14px;
      height: 14px;
    }
    /deep/.ant-radio-wrapper-checked {
      .ant-radio-inner::after {
        background: #afafaf;
      }
      .ant-radio-inner {
        border-color: #afafaf;
      }
      .ant-radio-inner::after {
        transform: translate(-11.5%, -11.5%);
      }
    }
    /deep/span.ant-radio + * {
      padding-right: 4px;
      padding-left: 4px;
    }
  }
}
.num-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  /deep/.ant-form-explain{
    text-align: center;
  }
  /deep/.ant-select-selection {
    // width: 100% !important;
  }
  /deep/.ant-form-explain {
    text-align: center;
  }
  /deep/.ant-select-search__field {
  text-align: center;
}
  .label {
    min-width: 75px;
    opacity: 1;
    // margin-bottom: 24px;
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0em;
    word-break: break-all;
    color: #101010;
  }
  .room-num /deep/.ant-select-selection {
    border-radius: 12px;
  }
  /deep/.ant-select-selection {
    height: 48px;
    border-radius: 12px;
    opacity: 1;
    background: #ffffff;
    border: 1px solid #afafaf;

    .ant-select-selection__rendered {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }
  }
  .room-num /deep/.ant-select-selection {
    width: 100% !important;
  }
}
.top-back {
  height: 44px;
  opacity: 1;

  /* 自动布局 */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 10px 1.75rem;
  gap: 4px;
  align-self: stretch;

  /* 底色 */
  background: #f2f2f2;

  z-index: 0;
}
.back-text {
}
.num-item {
  /deep/.has-error {
    .ant-select-selection {
      background: #fee0d6 !important;
      border-color: #df714f !important;

      svg {
        fill: #df714f;
      }
    }
    .ant-select-selection-selected-value {
      color: #df714f !important;
    }
    .ant-select-selection__placeholder {
      color: #df714f !important;
    }
  }
  /deep/.ant-form-explain {
    margin-top: 2px;
    line-height: 16px;
    color: #df714f;
    font-size: 14px;
    font-family: Arial;
  }
}
/deep/.ant-collapse-borderless {
  background: #fff;
}

// body :global(.ant-message-notice-content ){
//   width: 320px;
//   height: 108px;
//   padding: 32px !important;
//   border-radius: 32px !important;
//   background: #f8f7f8 !important;
// }
// body :global(.ant-message-custom-content.ant-message-info ){

//     height: 44px;
//     opacity: 1;
//     font-family: Arial;
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 22px;
//     display: flex !important;
//     align-items: center;
//     letter-spacing: 0em;
//     color: #101010;
//     .anticon.anticon-info-circle {
//       display: none;
//     }
// }
</style>
